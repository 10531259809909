@charset "utf-8";

// Import a Google Font
/*@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');*/
/*@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@700&display=swap');*/

html {
  height: 100%;
  font-size: 13px !important;
}

body {
  background-color:#F4F5F8;
  min-height: 100%;
  font-size: 14px;
}

// Update Bulma's global variables
$family-sans-serif: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
$menu-label-color: #D4D7DC;
$menu-item-color: #D4D7DC;
$account-menu-background: #383a3d;
$account-menu-link: #D4D7DC;
$account-menu-link-hover: #000;
$account-menu-hover: #f4f5f7;
$size-large: 16px;
$size-medium: 16px;
$radius-large: 16px;
$radius-rounded: 16px;
$radius: 16px;
$size-3: 1.5rem;
$body-size: 16px;

/* Colors */
$black: #1d1f20;
$blue: #485fc7;
$yellow: #fddc32;
$white: #fafafa;
$clean-white: #ffffff;
$coffewhite: #fafafa;

/*$scheme-main : $coffewhite;*/

body .input, body .textarea, body .select select, body .mySelect__control {
  background-color: $clean-white;
  box-shadow: none;
  border-radius: 12px;
}

.inventory-report .dot .dropdown-item .button_link {
    color: #4a4a4a;
    font-size: 0.875rem;
}

body .mobile .sales-report td {
  padding: 20px 10px;
}

#account-top-bar {
  margin-bottom: 0;
}

#account-sidebar-column.account-left-menu {
  margin-left: 10px;
}

.checkbox-selected-products {
  z-index: 10001;
}

body .box.short-filter .control .input,.box.short-filter  .button {
  font-size: 1rem;
}
.App .new-filters.show {
  width: 250px;
}
.sale-prepaid {
  border: 1px solid #coffewhite;
}
.App .is-one-even {
  background-color: white !important;
}
.inside-sale .is-one-six {
  width: 60px;
}
.short-filter svg:not(:root).svg-inline--fa {
  width: 20px;
}
.App .order-status {
    width: 145px;
}

.App .box.short-filter .control .input, .App .box.short-filter .button {
  height: 30px;
}

.modal-product-report .input {
  height: 30px;
}
/*// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;*/

.absolute {
  position: absolute;
  z-index: 10;
}

.App {
  text-align: center;
}

.new-filters .mySelect__value-container {
  height: 30px;
}

.menu svg:not(:root).svg-inline--fa {
  margin-right: 13px;
  height: 19px;
  width: 13px;
}

.wrapper-sidebar svg {
/*  display: none;*/
    filter: invert(92%) sepia(7%) saturate(127%) hue-rotate(192deg) brightness(90%) contrast(89%);
}

.wrapper-sidebar .sidebar-active svg {
  filter: invert(0%) sepia(100%) saturate(4140%) hue-rotate(45deg) brightness(107%) contrast(112%);
}

.wrapper-sidebar svg:hover {
  filter: invert(59%) sepia(46%) saturate(4320%) hue-rotate(179deg) brightness(100%) contrast(92%);
}
.collapse .menu-list a span  {
  left: 70px;
}
.App .main_button {
  margin-top: 0;
}
.hide-menu svg:not(:root).svg-inline--fa{ 
  height: 21px;
}

.new-filters { 
  background: $white;
}
.hide-menu svg:not(:root).svg-inline--fa {
  margin-right: 15px;
}
.App #account-sidebar-column.account-left-menu.collapse { 
  width: 70px;
  z-index: 33;
}

.App .hide-menu {
  margin-left: 16px;
}

#account-sidebar-column.account-left-menu.collapse .sub-menu li {
  text-align: left;
}

.App .short-filter .control.has-icons-left .icon {
  font-size: 13px !important;
}

.App > .content, .App .menu{
  text-align: left;
  //min-height: 900px;
  flex: 1;
}
.hide-menu a{
  font-size: 70%;
}
.collapse .menu-list li:hover .sub-menu, .collapse .menu-list .sub-menu:hover {
    color:$account-menu-link-hover;
    background: $account-menu-background;
    left: 1rem;
    width: 15rem;
}

#account-sidebar-column.account-left-menu .telegram-side a:hover {
  color: white;
}

.statisctic .heading {
  font-size: 0.7rem;
}

#account-sidebar-column {
  padding-right: 0px;
  margin-left: 15px;
  background: $account-menu-background;
}

.modal .sales-order-list .control.has-icons-right .icon {
  top: 3px;
}
.modal .control.has-icons-right .icon {
  top: 3px;
}
.menu-list.sub-menu {
    margin-left: 32px;
}
#account-sidebar-column.account-left-menu a:hover svg{ 
  filter: invert(0%) sepia(100%) saturate(4140%) hue-rotate(45deg) brightness(107%) contrast(112%);
}
#account-sidebar-column.account-left-menu a:hover {
  color:$account-menu-link-hover;
  background: $account-menu-hover;
}
#account-sidebar-column.account-left-menu .account-logo a:hover {
  background: transparent;
}
#account-sidebar-column.account-left-menu .account-logo a:hover img {
  transform: scale(1.05);
}
#account-sidebar-column.account-left-menu .account-logo a img {
  transition: transform 400ms linear;
}

#account-sidebar-column.account-left-menu.collapse a:hover {
  color:black;
}

.account-left-menu.collapse .user-info span{
  display: none;
}

#account-sidebar-column.account-left-menu.collapse a.sidebar-active {
  color:black;
  background: $account-menu-hover;
}

#account-sidebar-column.account-left-menu a.sidebar-active {
  color:$account-menu-link-hover;
  background: $account-menu-hover;
}

#account-sidebar-column.account-left-menu .sub-menu a {
  font-size: 0.9rem;
}
#account-sidebar-column.account-left-menu a {
  color:$account-menu-link;
  font-size: 1.2rem;
  font-weight: 400;
}
.sidebar-agreement {
  /*position: absolute;*/
  bottom: 20px;
  text-align: center;
  width: 100%;
}

#account-sidebar-column.account-left-menu.collapse li {
    text-align: center;
} 

.sales-actions .column .dot .button{ 
      margin-top: 4px;
}

#account-sidebar-column.account-left-menu {
  width: 220px;   
  min-height: 100vh;
  position: relative;
  margin-left: 0;
}

#account-sidebar-column.account-left-menu  .sidebar-agreement a{
  font-size: 75%;
}

.header-balance div{
  /*display: inline;*/
}
.header-balance{
  width: 200px;
}


// Import only what you need from Bulma
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/elements/button.sass";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/components/_all.sass";


/*@import "../../../node_modules/bulma/sass/grid/_all.sass";*/
/*@import "../../../node_modules/bulma/sass/elements/container.sass";*/
@import "../../../node_modules/bulma/sass/elements/_all.sass";
/*@import "../../../node_modules/bulma/sass/elements/title.sass";*/
@import "../../../node_modules/bulma/sass/form/_all.sass";
/*@import "../../../node_modules/bulma/sass/components/navbar.sass";*/
/*@import "../../../node_modules/bulma/sass/layout/hero.sass";*/
/*@import "../../../node_modules/bulma/sass/layout/section.sass";*/
/*@import "../../../node_modules/bulma/sass/layout/_all.sass";*/
/*@import "../../../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css";*/

